import { setupSGWTConnect } from '@sgwt/connect-core';
import type { ISGWTConnectIdTokenClaims } from '@sgwt/connect-core/dist/src/SGWTConnectIdTokenClaims';
import type { ISGWTConnectOpenIDStandardClaims } from '@sgwt/connect-core/dist/src/SGWTConnectOpenIDUserInfo';
import { getConfig } from '@/config/config';

const appConfig = getConfig();

export const useFake = appConfig?.env ? appConfig.env === 'local' || appConfig.env === 'devci' || appConfig.env === 'demo' : true;

// https://developer.mozilla.org/fr/docs/Web/API/window/location
export function getUrlParam(name: string): string | undefined {
  return unescape(window.location.search.replace(new RegExp(`^(?:.*[&\\?]${escape(name).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1')) || undefined;
}

const getNameFromEmail = (email: string) => email.split('@')[0].replace('-ext', '').replace('.', ' ');

function getFakeUserFromUrl() {
  return getUrlParam('user');
}

export const getFakeUser = () => getFakeUserFromUrl() || appConfig.fake_user;

const setupFakeSGConnect = () => {
  const originalSgConnect = setupSGWTConnect(appConfig.sgconnect);
  const fakeUserEmail = getFakeUser()!;

  originalSgConnect.getIdTokenClaims = () =>
    ({
      sub: fakeUserEmail,
    } as ISGWTConnectIdTokenClaims);

  const originalFetchUserInfo = originalSgConnect.fetchUserInfo.bind(originalSgConnect);
  originalSgConnect.fetchUserInfo = (callback) => {
    originalFetchUserInfo((error, claims) => {
      const fakeUser = {
        ...claims,
        sub: fakeUserEmail,
        mail: fakeUserEmail,
        name: getNameFromEmail(fakeUserEmail),
      } as ISGWTConnectOpenIDStandardClaims;
      callback(error, fakeUser);
    });
    return originalSgConnect;
  };

  return originalSgConnect;
};

export const sgwtConnect = useFake ? setupFakeSGConnect() : setupSGWTConnect(appConfig.sgconnect);

(window as any).sgwtConnect = sgwtConnect;
