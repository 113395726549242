import 'core-js/es/array';
import 'core-js/es/symbol';
import 'core-js/es/array/includes';
import 'core-js/es/string/includes';

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { createRoot } from 'react-dom/client';

import { EVENT_AUTH_DISCARDED, EVENT_AUTH_EXPIRED, EVENT_AUTH_RENEW_ERROR, EVENT_AUTH_RENEW_SUCCESS } from '@sgwt/connect-core';
import { IntlProvider } from 'react-intl';
import { Provider, useSelector } from 'react-redux';
import { throwError } from 'rxjs';

import { App } from '@/App/App';
import { logger } from '@/logging/logger';
import { connectToStreaming } from '@/services/streaming.service';
import { UserService } from '@/services/user.service';
import { sgwtConnect } from '@/sgwtConnect';
import { navigableAsUsersLoadedAction, userLoadedAction } from '@/store/state/user/user.actions';
import { type AppDispatch, createStore, useAppDispatch } from '@/store/store';
import '@/index.scss';
import { CrashModal } from '@/App/utils/CrashModal';
import { getConfig } from '@/config/config';
import { toLogEvents$ } from '@/services/streams.service';
import { streamingConnectedAction } from '@/store/state/streaming/streaming.actions';
import { toggleNavigateAsModalAction } from '@/store/state/ui/ui.actions';
import type { SgwtConnectHTMLElement } from '@/typings/sgwt-widgets';
import { messages } from '@/utils/locale';
import { MatomoContext, useMatomo } from '@/utils/useMatomo';
import { ServiceLoader } from '@sgme/ui';
import { type AcrValue, SgwtConnect, SgwtWebAnalytics, SgwtWidgetContextProvider, useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { finalize, tap } from 'rxjs/operators';
import { selectCurrentLocale } from './store/state/ui/ui.selectors';
import { initSgBootstrap } from './utils/theme';

if (sgwtConnect.isAuthorized()) {
  initAppForAuthorizedUser();
} else {
  displayErrorForUnauthorisedUser();
}

//  █████╗ ██╗   ██╗████████╗██╗  ██╗ ██████╗ ██████╗ ██╗███████╗███████╗██████╗     ██╗   ██╗███████╗███████╗██████╗
// ██╔══██╗██║   ██║╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██║╚══███╔╝██╔════╝██╔══██╗    ██║   ██║██╔════╝██╔════╝██╔══██╗
// ███████║██║   ██║   ██║   ███████║██║   ██║██████╔╝██║  ███╔╝ █████╗  ██║  ██║    ██║   ██║███████╗█████╗  ██████╔╝
// ██╔══██║██║   ██║   ██║   ██╔══██║██║   ██║██╔══██╗██║ ███╔╝  ██╔══╝  ██║  ██║    ██║   ██║╚════██║██╔══╝  ██╔══██╗
// ██║  ██║╚██████╔╝   ██║   ██║  ██║╚██████╔╝██║  ██║██║███████╗███████╗██████╔╝    ╚██████╔╝███████║███████╗██║  ██║
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝╚═════╝      ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

function initAppForAuthorizedUser() {
  initSgBootstrap();

  const rootElement = document.getElementById('root');

  if (!rootElement) throw new Error('no root');

  const root = createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <SgwtWidgetContextProvider infrastructure="azure">
        <ServiceLoader>
          <MatomoContext>
            <ApplicationWithStore />
          </MatomoContext>
        </ServiceLoader>
      </SgwtWidgetContextProvider>
      ,
    </React.StrictMode>,
  );
}

function ApplicationWithStore() {
  const { trackAction, trackRfq, updateSelectedCompanyName } = useMatomo();

  const store = useMemo(() => {
    return createStore(trackAction, trackRfq, updateSelectedCompanyName);
  }, [trackAction, trackRfq, updateSelectedCompanyName]); // never change -> see useMatomo comments

  const {
    piwik: { url, site_id },
    sgconnect, // TODO: replace piwik by matomo
    env,
  } = getConfig();

  return (
    <Provider store={store}>
      <ConnectedIntlProvider>
        <SgwtWebAnalytics
          siteId={site_id}
          baseUrl={url}
          debug={env === 'devci'} // To show debug on homolo env
        />
        <SgwtConnect clientId={sgconnect.client_id} scope={sgconnect.scope} redirectUri={sgconnect.redirect_uri} acrValues={sgconnect.acr_values as AcrValue | undefined} />
        <CrashModal />
        <Application />
      </ConnectedIntlProvider>
    </Provider>
  );
}

function ConnectedIntlProvider({ children }: React.PropsWithChildren<Record<string, unknown>>) {
  const locale = useSelector(selectCurrentLocale);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}

type ApplicationStatus = 'loading' | 'ready' | 'no-company' | 'not-allowed' | 'error';

function Application() {
  const dispatch = useAppDispatch();
  const { sgwtConnect } = useSgwtWidgets();

  const [status, setStatus] = useState<ApplicationStatus>('loading');

  useEffect(() => {
    subscribeToStreamEvents(dispatch);
    subscribeToSGConnectEvents();
  }, [dispatch]);

  useEffect(() => {
    if (sgwtConnect === undefined) {
      return;
    }

    const subscription = UserService.getCurrentUser().subscribe(
      (user) => {
        if (!user.myFx) {
          setStatus('not-allowed');
          return;
        }

        if (user.companies.length === 0) {
          setStatus('no-company');
          return;
        }

        dispatch(userLoadedAction(user));

        setupSgwtConnectWidget(sgwtConnect);

        if (!user.canNavigateAs) {
          setStatus('ready');
          return;
        }

        UserService.getNavigableAsUsers()
          .pipe(
            tap(() => dispatch(toggleNavigateAsModalAction())),
            finalize(() => setStatus('ready')),
          )
          .subscribe(
            (users) => dispatch(navigableAsUsersLoadedAction(users)),
            (err) => logger.logError('Error loading users', err),
          );
      },
      (error) => {
        if (error.status === 401 || error.status === 403) {
          setStatus('not-allowed');
        } else {
          setStatus('error');
        }

        return throwError(error);
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [sgwtConnect, dispatch]);

  switch (status) {
    case 'loading':
      return <ServiceLoader />;
    case 'ready':
      return <App />;
    case 'no-company':
      return <NoCompany />;
    case 'not-allowed':
      return <NotAllowed />;
    case 'error':
      return <ErrorComponent />;
  }
}

function subscribeToStreamEvents(dispatch: AppDispatch) {
  connectToStreaming().then((streamingInfo) => {
    dispatch(streamingConnectedAction(streamingInfo));

    // logs some important events, useful for support
    toLogEvents$().subscribe((event) => logger.logInformation('Received event: {event}', event));
  });
}

function subscribeToSGConnectEvents() {
  sgwtConnect.on(EVENT_AUTH_RENEW_SUCCESS, () => logger.logInformation('sgwtConnect token renew'));
  sgwtConnect.on(EVENT_AUTH_DISCARDED, () => logger.logInformation('sgwtConnect token is no longer available on the client side.'));
  sgwtConnect.on(EVENT_AUTH_EXPIRED, () => logger.logInformation('sgwtConnect token is no longer valid.'));
  sgwtConnect.on(EVENT_AUTH_RENEW_ERROR, (error) => logger.logError('sgwtConnect failed to renew the token', JSON.stringify(error)));
}

function setupSgwtConnectWidget(widget: SgwtConnectHTMLElement) {
  if (widget) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);

        // Remove the event listener
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}

function NoCompany() {
  return <div className="center-screen">{`Vous n'avez pas de société rattachée à votre compte`}</div>;
}

function NotAllowed() {
  location.href = window.sgmeConfiguration.redirect_uri;

  return <div className="center-screen">{`Vous n'avez pas les droits d'accéder à la plateforme`}</div>;
}

function ErrorComponent() {
  return <div className="center-screen">Une erreur est survenue sur la plateforme, nous vous invitons à réessayer plus tard</div>;
}

// ███╗   ██╗ ██████╗ ████████╗     █████╗ ██╗   ██╗████████╗██╗  ██╗ ██████╗ ██████╗ ██╗███████╗███████╗██████╗     ██╗   ██╗███████╗███████╗██████╗
// ████╗  ██║██╔═══██╗╚══██╔══╝    ██╔══██╗██║   ██║╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██║╚══███╔╝██╔════╝██╔══██╗    ██║   ██║██╔════╝██╔════╝██╔══██╗
// ██╔██╗ ██║██║   ██║   ██║       ███████║██║   ██║   ██║   ███████║██║   ██║██████╔╝██║  ███╔╝ █████╗  ██║  ██║    ██║   ██║███████╗█████╗  ██████╔╝
// ██║╚██╗██║██║   ██║   ██║       ██╔══██║██║   ██║   ██║   ██╔══██║██║   ██║██╔══██╗██║ ███╔╝  ██╔══╝  ██║  ██║    ██║   ██║╚════██║██╔══╝  ██╔══██╗
// ██║ ╚████║╚██████╔╝   ██║       ██║  ██║╚██████╔╝   ██║   ██║  ██║╚██████╔╝██║  ██║██║███████╗███████╗██████╔╝    ╚██████╔╝███████║███████╗██║  ██║
// ╚═╝  ╚═══╝ ╚═════╝    ╚═╝       ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝╚═════╝      ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

function displayErrorForUnauthorisedUser() {
  const authError = sgwtConnect.getAuthorizationError();

  if (authError) {
    // do something meaningful with the error
    // eslint-disable-next-line no-alert
    alert(JSON.stringify(authError));
  } else {
    sgwtConnect.requestAuthorization();
  }
}
