import { type ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, type InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import './CrashModal.scss';
import { logger } from '@/logging/logger';
import type { AppState } from '@/store/app.state';
import { noop } from '@/utils/noop';
import { isDefined } from '@sgme/fp';
import { unminifyStackTrace } from '@sgme/stacktrace';
import { UncontrolledCollapsable } from './Collapsable';
import { HelpButton, type SendHelpStatus } from './HelpButton';

interface CrashModalProps {
  error: string | null;
}

const reload = () => window.location.reload();

const TechnicalDetails = styled.pre`
  min-height: 100px;
  max-height: 500px;
  overflow: auto;
`;

const CrashModalRaw = ({ error, intl }: CrashModalProps & InjectedIntlProps) => {
  const [userDetails, setUserDetails] = useState('');
  const [stackTrace, setStackTrace] = useState<string | null | undefined>('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const handleError = async (error: string) => {
      let errorMessage = '';
      if (isDefined(error) && typeof error === 'string') {
        errorMessage = error.substring(0, error.indexOf('\n'));
      }
      const beautifulStackTrace = await unminifyStackTrace(error);
      setStackTrace(beautifulStackTrace);
      setErrorMessage(errorMessage);
    };

    if (error) {
      handleError(error).catch((err) => {
        logger.logError('Unable to translate stack trace {message_s}', err?.message);
      });
    }
  }, [error]);

  if (error === null) {
    return;
  }

  const handleChangeUserDetails = (event: ChangeEvent<HTMLTextAreaElement>) => setUserDetails(event.currentTarget.value);

  const getDetails = () => (userDetails !== '' ? `${userDetails}\n<b>Technical error:</b> ${error}` : null);

  const sendHelpRequestCallback = (status: SendHelpStatus) => {
    if (status === 'success') {
      reload();
    }
  };

  return (
    <Modal size="lg" isOpen toggle={noop} className="CrashModal">
      <ModalHeader tag="h4" className="text-danger">
        <FormattedMessage id="app.crash.title" />
      </ModalHeader>

      <ModalBody>
        <FormattedMessage tagName="p" id="app.crash.body" />

        <textarea
          className="form-control w-100"
          rows={4}
          placeholder={intl.formatMessage({
            id: 'app.crash.userDetailsPlaceholder',
          })}
          onChange={(e) => handleChangeUserDetails(e)}
          value={userDetails}
        />

        <UncontrolledCollapsable title={intl.formatMessage({ id: 'app.crash.moreDetails' })} collapsed>
          <TechnicalDetails className="border bg-lvl2 text-primary mt-3 p-3 text-start">
            <h3>Message</h3>
            <code>{errorMessage}</code>
            <h4>StackTrace</h4>
            <code>{stackTrace}</code>
          </TechnicalDetails>
        </UncontrolledCollapsable>
      </ModalBody>

      <ModalFooter>
        {userDetails !== '' ? (
          <HelpButton labelId="app.crash.sendAndReload" details={getDetails} statusUpdateCallBack={sendHelpRequestCallback} />
        ) : (
          <button type="button" className="btn btn-primary" onClick={reload}>
            <FormattedMessage id="app.crash.reload" />
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state: AppState): CrashModalProps => {
  return {
    error: state.appCrash.error,
  };
};

export const CrashModal = connect(mapStateToProps)(injectIntl(CrashModalRaw));
