import { sendHelpRequest } from '@/services/helpRequest.service';
import { sgwtConnect } from '@/sgwtConnect';
import type { AppStore } from '@/store/store';
import { assertUnreachable } from '@/utils/asserts';
import { cleanUpSensitiveInformations } from '@/utils/cleanUpSensitiveInformations';
import { http } from '@/utils/http';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStore } from 'react-redux';

export type SendHelpStatus = 'sending' | 'failed' | 'success';

interface IHelpButtonOwnProps {
  labelId?: string;
  details(): any;
  statusUpdateCallBack?(status: SendHelpStatus | null): void;
}

interface IHelpButtonState {
  sendHelpStatus: SendHelpStatus | null;
}

const stringifyIfNeeded = (value: any): string => {
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(value, null, 2);
};

export class HelpButton extends React.Component<IHelpButtonOwnProps, IHelpButtonState> {
  constructor(props: IHelpButtonOwnProps) {
    super(props);
    this.state = { sendHelpStatus: null };
  }

  private sendHelp = () => {
    const { details } = this.props;

    this.updateStatus('sending');

    // special use case: we need to get
    // - the full state
    // - the last executed actions
    const store = useStore() as AppStore;

    sendHelpRequest(
      http,
      sgwtConnect,
    )({
      application: 'SGME-MY-FX',
      config: 'appcrashed',
      details: stringifyIfNeeded(details()),
      lastActions: JSON.stringify(store.getLastActions(), cleanUpSensitiveInformations),
      state: JSON.stringify(store.getState()),
      sendConfirmation: true,
    })
      .toPromise()
      .then(() => this.updateStatus('success'))
      .catch((reason) => {
        // console.error('Could not send help request', reason);
        this.updateStatus('failed');
      });
  };

  private updateStatus = (sendHelpStatus: SendHelpStatus | null) => {
    const { statusUpdateCallBack } = this.props;
    this.setState({ sendHelpStatus });
    if (statusUpdateCallBack) {
      statusUpdateCallBack(sendHelpStatus);
    }
  };

  public render() {
    const { sendHelpStatus } = this.state;
    const { labelId } = this.props;

    switch (sendHelpStatus) {
      case null:
        return (
          <button type="button" className="btn btn-primary" onClick={this.sendHelp}>
            <FormattedMessage id={labelId ?? 'app.crash.helpbutton.send'} />
          </button>
        );

      case 'failed':
        return (
          <button type="button" className="btn btn-icon-text btn-danger" onClick={this.sendHelp}>
            <i className="icon icon-xs">close</i>
            <FormattedMessage id="app.crash.helpbutton.fail" />
          </button>
        );

      case 'sending':
        return (
          <button type="button" className="btn btn-icon-text" disabled>
            <i className="me-2 spinner spinner-sm" />
            <FormattedMessage id="app.crash.helpbutton.sending" />
          </button>
        );

      case 'success':
        return (
          <span className="text-success mx-2">
            <i className="icon me-1">check</i>
            <FormattedMessage id="app.crash.helpbutton.success" />
          </span>
        );

      default:
        assertUnreachable('Unhandled state for HelpButton', sendHelpStatus);
    }
  }
}
