import type { CurrentUser, User } from '@/models/user';
import { selectCurrentLocale } from '@/store/state/ui/ui.selectors';
import { type AccountCenterNavigateAsItem, SgwtAccountCenter, useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import type { SgwtWidgetUser } from '@sgwt/sgwt-widgets-react/dist/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

interface AccountCenterProps {
  navigateAs: boolean | undefined;
  navigateAsList: AccountCenterNavigateAsItem[] | undefined;
  navigateAsUser: CurrentUser | undefined;
  languageChanged: (locale: string) => void;
  navigateAsSelectUser: (user: User) => void;
  stopNavigationAs: () => void;
  showNavigateAsModal: boolean;
  blockExecution: (blockExecution: boolean) => void;
}

export function AccountCenter({
  blockExecution,
  languageChanged,
  navigateAs,
  navigateAsList,
  navigateAsSelectUser,
  navigateAsUser,
  showNavigateAsModal,
  stopNavigationAs,
}: AccountCenterProps) {
  const accountCenterId = 'my-account-center';

  const availableLanguages = ['fr', 'en'];
  const locale = useSelector(selectCurrentLocale);
  const environment = !window.sgmeConfiguration.env.includes('prod') ? window.sgmeConfiguration.env : undefined;

  const { sgwtAccountCenter } = useSgwtWidgets();

  useEffect(() => {
    if (navigateAs && showNavigateAsModal) {
      if (sgwtAccountCenter?.showNavigateAs) {
        sgwtAccountCenter.showNavigateAs();
      }
    }
  }, [sgwtAccountCenter, navigateAs, showNavigateAsModal]);

  useEffect(() => {
    const onHelpOpen = () => blockExecution(true);
    const showUserCard = 'sgwt-account-center--show-user-card';

    sgwtAccountCenter?.addEventListener(showUserCard, onHelpOpen);

    return () => {
      sgwtAccountCenter?.removeEventListener(showUserCard, onHelpOpen);
    };
  }, [sgwtAccountCenter, blockExecution]);

  return (
    <SgwtAccountCenter
      id={accountCenterId}
      authentication="sg-connect"
      availableLanguages={availableLanguages}
      debug={environment === 'homologation'} // To show debug on homolo env
      mode="sg-markets"
      producerCode="sgm_exec_fx"
      environment={environment}
      language={locale}
      onLanguageChanged={languageChanged}
      navigateAs={navigateAs}
      navigateAsList={navigateAsList}
      navigateAsUser={navigateAsUser as unknown as SgwtWidgetUser}
      onNavigateAsSelectUser={navigateAsSelectUser as unknown as (user: SgwtWidgetUser) => void}
      onStopNavigationAs={stopNavigationAs}
    />
  );
}

// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

interface LanguageEvent {
  language: string;
}

// events
const languageChanged = 'sgwt-account-center--language-changed';
const navigateAsLinkClicked = 'sgwt-account-center--navigate-as-link-clicked';
const navigateAsSelectUser = 'sgwt-account-center--navigate-as-select-user';
const ready = 'sgwt-account-center--ready';
const signIn = 'sgwt-account-center--sign-in';
const signOut = 'sgwt-account-center--sign-out';
const stopNavigationAs = 'sgwt-account-center--stop-navigation-as';
const showNotificationCard = 'sgwt-account-center--show-notification-card';
const hideNotificationCard = 'sgwt-account-center--hide-notification-card';
const showServicesCard = 'sgwt-account-center--show-my-services-card';
const hideServicesCard = 'sgwt-account-center--hide-my-services-card';
const showUserCard = 'sgwt-account-center--show-user-card';
const hideUserCard = 'sgwt-account-center--hide-user-card';

interface AccountCenterElementEventMap {
  [languageChanged]: CustomEvent<LanguageEvent>;
  [navigateAsLinkClicked]: CustomEvent;
  [navigateAsSelectUser]: CustomEvent<{ user: User }>;
  [ready]: Event;
  [signIn]: CustomEvent<User>;
  [signOut]: Event;
  [stopNavigationAs]: CustomEvent<{ user: User }>;
  [showNotificationCard]: CustomEvent;
  [hideNotificationCard]: CustomEvent;
  [showServicesCard]: CustomEvent;
  [hideServicesCard]: CustomEvent;
  [showUserCard]: CustomEvent;
  [hideUserCard]: CustomEvent;
}

interface AccountCenterElement extends Element {
  addEventListener<K extends keyof AccountCenterElementEventMap>(
    type: K,
    listener: (this: AccountCenterElement, ev: AccountCenterElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener<K extends keyof AccountCenterElementEventMap>(
    type: K,
    listener: (this: AccountCenterElement, ev: AccountCenterElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions,
  ): void;
  changeLanguage(newLanguage: string): void;
  setUser(user: User): void;
  setNavigateAsUser(user: User): void;
  signOut(): void;
  stopNavigationAs(): void;
  showNavigateAs(): void;
}
